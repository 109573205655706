<template>
  <div class="w-100 h-100 d-flex justify-center align-center">
    <componentVerification />
  </div>
</template>

<script>
import componentVerification from '../../components/login/Verification.vue'

export default {
  name: 'Verification',
  components: {
    componentVerification
  }
}
</script>
