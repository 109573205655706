<template>
  <div>
    <v-img @click="$router.push({name: 'Login'})" class="mx-auto mb-15 mb-sm-10" src="@/assets/img/logos/auth-logo.png"
           :height="$vuetify.breakpoint.smAndUp? '196px': '147px'" contain style="cursor: pointer;"></v-img>
    <p class="auth-title mb-0">Verificación</p>
    <v-form class="verification-form" @submit.prevent="verificar" ref="form">
      <div>
        <v-row>
          <v-col class="d-flex flex-column">
            <p class="mx-auto text-center auth-text mb-2" style="max-width: 312px;">
              Te hemos enviado un código para verificar tu cuenta.
            </p>
            <div class="align-self-center">
              <p class="email grey--text darken-2">usamedic@gmail.com</p>
            </div>
          </v-col>
          <v-col cols="12" class="pt-0">
            <base-text-field
              label="Código"
              v-model="codigo"
              :rules="[rules.required]"
            ></base-text-field>
          </v-col>
          <v-col cols="12">
            <base-button class="full-width" type="submit">Continuar</base-button>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <LoadingModal v-model="loading"></LoadingModal>
    <ConfirmModal ref="authConfirm"/>
  </div>
</template>

<script>
import { required } from '@/utils/rules'
import axios from 'axios'
import LoadingModal from '@/components/emergentesGlobales/LoadingModal'
import ConfirmModal from '@/components/auth/ConfirmModal'
import BaseButton from '@/components/auth/BaseButton'
import BaseTextField from '@/components/auth/BaseTextField'

export default {
  components: {
    LoadingModal,
    ConfirmModal,
    BaseButton,
    BaseTextField
  },
  data () {
    return {
      codigo: '',
      loading: false,
      rules: {
        required
      }
    }
  },
  mounted () {
    this.codigo = this.$route.params.codigo
  },
  methods: {
    async verificar () {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await axios.post('api/rest-auth/registration/verify-email/', { key: this.codigo })
          await this.$refs.authConfirm.open({
            type: 'feedback',
            title: '¡Felicidades!',
            message: 'Su email ha sido verificado correctamente.',
            acceptText: 'Confirmar'
          })
          this.$router.push({ name: 'Login' })
        } catch (e) {
          this.loading = false
          if (e.response.data.detail) {
            this.titleAlert = 'Lo sentimos'
            this.messageAlert = e.response.data.detail
          } else {
            this.titleAlert = '¡Ups!'
            this.messageAlert = 'Ha ocurrido un error inesperado. Intentelo nuevamente en unos minutos.'
          }
          await this.$refs.authConfirm.open({
            type: 'error',
            title: this.titleAlert,
            message: this.messageAlert,
            acceptText: 'Confirmar'
          })
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>
.verification-form {
  margin: auto;
  padding: 0;
  max-width: 448px;
}
</style>
